import { currencyFormatter } from "helpers/util";
import moment, { unitOfTime } from "moment";

export const toChartData = (name: string, dateRange: {start, end}, {start, end, data}: {start: any, end: any, data: any[]}, forecastCount: number = null): any => {

    if (data.length == 0) {
        return emptyData();
    }

    
    const {profit, min, max} = data.reduce((a, d) => {
        a.profit += sumBuySell(d.profit);
        a.min = Math.min(a.min, a.profit);
        a.max = Math.max(a.max, a.profit);
        return a;
    }, {profit: 0, min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER});

    const profitPrct = calcProfitPrct(start.balance, profit);

    let profitSeries = 0;
    let endPoint = null;
    const chartData = data.map((d) => {
        profitSeries += sumBuySell(d.profit);
        const xyArray = [d.dateTs, toFloat(profitSeries)];
        if (!d.isForecast) {
            // point before forecast starts
            endPoint = xyArray; 
        }
        return xyArray;
    });

    const res = {
        dateStart: dateRange ? moment(dateRange.start).valueOf() : null,
        dateEnd: dateRange ? moment(dateRange.end).valueOf() : null,
        balanceStart: start.balance, 
        balanceEnd: forecastCount > 0 ? end.forecastBalance : end.balance, 
        depositsTotal: end.deposits - start.deposits, 
        withdrawsTotal: end.withdrawels - start.withdrawels,
        value: profit.toFixed(2), valuePrct: profitPrct, 
        ...(forecastCount && {forecastCount}),
        ...(endPoint && {points: [{
            x: endPoint[0],
            y: endPoint[1],
            marker: {
              size: forecastCount > 0 ? 6 : 3,
              strokeColor: "#f1b44c",
            },
            ...(forecastCount > 0 && {label: {style: {fontSize: "14px"}, offsetY: -4, fontSize: "16px", text: currencyFormatter(end.balance)}})},
        ],
        series: [
            {name, min, max, data: chartData}, 
        ]})
    };
    return res;
}


function sumBuySell({buy, sell}) {
    return (buy || 0) + (sell || 0);
}

function toFloat(value: number, dp: number = 2) {
    if (!value) {
        return value;
    }
    return parseFloat(value.toFixed(dp));
}

export const getDateRange = (periodAgo: number, period: unitOfTime.Base): any => {
    const currentDate = moment().utc(false).subtract(periodAgo, period);
    const start = currentDate.clone().startOf(period);
    const end = currentDate.clone().endOf(period);
    // console.log("start", start.format("ddd D MMM YYYY HH:mm"), start.toISOString());
    // console.log("end", end.format("ddd D MMM YYYY HH:mm"), end.toISOString());
    return {start: start.toISOString(), end: end.toISOString()};
}

export const emptyData = (): any => {
    return {series: [{name, data: []}]};
}

export const calcProfitPrct = (balance: number, profit: number): number => {
    if (!balance || !profit) {
        return 0;
    }
    return toFloat(((balance + profit) / balance) - 1)
}