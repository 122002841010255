import CurrencyFormat from "components/Common/CurrencyFormat";
import PlaceHolder from "components/Common/PlaceHolder";
import PrctFormat from "components/Common/PrctFormat";
import { Card, CardBody } from "reactstrap";

const CardResult = ({title, value, prctValue = null, icon = null, isProfitLoss = false, className = "", isLoading = false}) => {
    
    return <Card className={"mini-stats-widget " + className}>
        <CardBody>
            <div className="d-flex">
                <div className="flex-grow-1">
                    <p className="text-muted fw-medium">
                    {title}
                    </p>
                    <h4 className="mb-0"><CurrencyFormat value={value} isProfitLoss={isProfitLoss} isLoading={isLoading}/></h4>
                </div>
                {icon && <div className="ms-2 avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-primary">
                        <i className={"bx " + icon + " font-size-24"}></i>
                    </span>
                </div>}
                {prctValue && <div className="ms-2 align-self-center">
                    {/* <span className="avatar-title rounded-circle bg-primary"> */}
                        <h3><PrctFormat value={prctValue} isProfitLoss={isProfitLoss} isLoading={isLoading}/></h3>
                    {/* </span> */}
                </div>}
            </div>
        </CardBody>
    </Card>
}

export default CardResult;