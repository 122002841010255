import CurrencyFormat from "components/Common/CurrencyFormat";
import Spinner from "components/Common/Spinner";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Badge, Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, Tooltip, UncontrolledTooltip } from "reactstrap";
import { deleteBot, updateBot } from "store/actions";
import LoginModal from "./LoginModal";
import AdminUserProfileView from "pages/User/AdminUserProfileView";
import { isNotNull } from "helpers/util";
import BotStatus from "./BotStatus";
import BotIcon from "./BotIcon";
import DateFromNow from "components/Common/DateFromNow";

const BotsTable = ({ botsData, contacts, currentUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const [drpLink, setDrpLink] = useState("");
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [loginModalData, setLoginModalData] = useState({});
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [moreData, setMoreData] = useState([]);

    const toggleShowUserProfile = () => {
      setShowUserProfile(!showUserProfile);
    }

    const onShowInfo = (bot: any) => {
      setSelectedUser(contacts[bot.userId]);
      setMoreData([
        {
          id: "robot-info",
          title: "Robot Information",
          items: [
            {
              id: "server",
              name: "Broker Server",
              value: bot.server,
              isCopy: true,
            },
            {
              id: "accountNumber",
              name: "Account #",
              value: bot.loginFull || bot.login,
              isCopy: true,
            },
            {
              id: "eaName",
              name: "Expert Advisor",
              value: bot.eaName
            },
            {
              id: "botId",
              name: "Robot ID",
              value: bot.id,
              isCopy: true,
            },
            {
              id: "instanceId",
              name: "Instance ID",
              value: bot.instanceId,
              isCopy: true,
            },
            {
              id: "versionId",
              name: "Robot Version",
              value: bot.tradeInfo?.robotVersion
            },
          ]
        }
      ]);
      setShowUserProfile(true);
    }

    const openUpdateLoginModal = (bot) => {
      setLoginModalData(bot);
      setOpenLoginModal(true);
    }

    const getStateNext = (bot: any) => {
      return canStop(bot.state) ? "Stop" : "Start";
    }
  
    const isDisabled = (bot: any) => {
      return !(canStart(bot.state) || canStop(bot.state))
    }
  
    const canStart = (state: string): boolean => {
      return ["DEPLOYED", "STOPPED"].indexOf(state) >= 0;
    }
    
    const canStop = (state: string): boolean => {
      return ["RUNNING", "STARTED"].indexOf(state) >= 0;
    }
  
    const onStartStopBot = (bot: any) =>  {
      if (canStop(bot.state)) {
        dispatch(updateBot("STOP", bot, {}));
      } else if (canStart(bot.state)) {
        dispatch(updateBot("START", bot, {}));
      }
    }
  
    const onDeleteBot = (bot: any) =>  {
      dispatch(deleteBot(bot));
    }
  
    return (
      <div className="table-responsive">
        {currentUser?.claims?.admin && <AdminUserProfileView user={selectedUser} show={showUserProfile} toggleOpen={toggleShowUserProfile} moreData={moreData}/>}
                
        { openLoginModal && <LoginModal open={openLoginModal} setOpen={setOpenLoginModal} data={loginModalData}/>}
        <Table className="table table-hover mb-0">
          <thead className="table-light">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Status</th>
              <th>Open Trades</th>
              <th>Balance</th>
              {currentUser?.claims?.admin && <th>Runtime</th>}
              <th>Last Update</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="pb-4">
            {botsData.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map(bot => ({...bot, ...(bot.tradeInfo && bot.tradeInfo)})).map((bot: any) => (     
              <tr key={bot.id}>
                <td>
                  <BotIcon bot={bot} onClick={() => navigate(`/result/${bot.id}`)}/>
                </td>
                {/* <th scope="row" onClick={() => navigate(`/bots/${bot.id}`)}>{bot.name}</th> */}
                <td>
                  <strong>{bot.name}</strong>
                  <p>{bot.description}</p>
                  <p className="text-muted">
                    Account: {bot.login}
                    <Link to="#">
                            <i id={`accTooltip-${bot.id}`} className="mdi mdi-information-outline ms-1 font-size-16"/>
                            
                            <UncontrolledTooltip className="text-start" placement="top" target={`accTooltip-${bot.id}`}>
                              <Row>
                                <Col className="text-start">Broker:</Col><Col className="text-end text-nowrap">{bot.broker}</Col> 
                              </Row>
                              <Row>
                                <Col className="text-start">Server:</Col><Col className="text-end text-nowrap">{bot.server}</Col> 
                              </Row>
                              <Row>
                                <Col className="text-start">Robot:</Col><Col className="text-end text-nowrap">{bot.eaName}</Col> 
                              </Row>
                            </UncontrolledTooltip>
                        </Link>
                  </p>
                  {
                    currentUser?.claims?.admin && 
                    <p className="text-muted">
                        <Link to="#" onClick={() => onShowInfo(bot)}>
                            {contacts[bot.userId]?.displayName || bot.userId}
                        </Link>
                    </p>
                  }
                </td>
                <td>
                  <BotStatus bot={bot} onUpdateLogin={openUpdateLoginModal}/>
                </td>
                <td>
                  { 
                    isNotNull(bot.equity) 
                    ? <><CurrencyFormat isProfitLoss={true} value={bot.equity} currency={bot.currency}/>
                        <span className="ms-1">({bot.positionCount || 0})</span></>
                    : <div> - </div>
                  }
  
                </td>
                <td>
                  <CurrencyFormat value={bot.balance} currency={bot.currency}/> <br/>
                  <CurrencyFormat isProfitLoss={true} value={bot.profitLoss} currency={bot.currency}/> <br/>
                </td>
                {currentUser?.claims?.admin && <td><DateFromNow dateTime={!bot.isDownload && bot.createdAt}/></td>}
                <td><DateFromNow dateTime={!bot.isDownload && bot.lastModified}/></td>
                <td>
                  
                  <div className="btn-group"
                          role="group">

                    <ButtonDropdown
                        isOpen={drpLink === bot.id}
                        toggle={() => setDrpLink(drpLink === bot.id ? "": bot.id)}>

                      {!bot.isDownload && <Button onClick={() => onStartStopBot(bot)} id="caret" color="primary" disabled={isDisabled(bot)}>
                        {getStateNext(bot)}
                      </Button>}
                      {bot.isDownload && <Link to={`/bots/download/${bot.id}`} id="caret" className="btn btn-primary">
                        Downlaod
                      </Link>}
                      <DropdownToggle caret color="primary">
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => onDeleteBot(bot)}>Delete</DropdownItem>
                      </DropdownMenu>

                    </ButtonDropdown>

                    <Link title="Results" to={`/result/${bot.id}`} className="btn btn-outline-primary">
                      <i className="mdi mdi-chart-areaspline text-success-subtle"></i>
                    </Link>

                  </div>

                  {bot.deleted && <span className="float-end text-danger"><i className="mdi mdi-close font-size-16" /></span>}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  export default BotsTable;