import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import {
    Label, Col, Container, Row, Card, CardBody, Input, CardTitle, Form, FormFeedback, Button
} from "reactstrap";
import Select from "react-select";
import Spinner from "components/Common/Spinner";

import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

import { useFormik } from "formik";

import { listBrokerServers, listBrokers } from "store/broker/actions";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { newBot as onNewBot } from "store/bot-trader/actions";
import { Link } from "react-router-dom";

const NewBotBrokerPage = (props) => {

    //meta title
    document.title = "New Robot | Vertex Robot Trading";

    const dispatch = useDispatch();
    const serverInputRef = useRef();

    const {
        newBot, loading, creating
    } = useSelector(createSelector(
        (state: any) => (state.bots),
        (bots: any) => {
            return {
                newBot: bots.newBot,
                loading: bots.loading,
                creating: bots.creating,
            };
        }
    ));

    const {
        brokers, brokerServers, brokerLoading
    } = useSelector(createSelector(
        (state: any) => (state.broker),
        (broker: any) => {
            return {
                brokers: broker.brokers,
                brokerServers: broker.brokerServers,
                brokerLoading: broker.loading,
            };
        }
    ));

    const [servers, setServers] = useState([]);

    useEffect(() => {
        if (brokerServers && newBot) {
            setServers(brokerServers.filter(b => b.brokerId == newBot.broker?.value));
        } 
    }, [brokerServers, newBot]);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            broker: newBot.broker,
            server: newBot.server,
            login: newBot.login,
            password: newBot.password
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.broker) {
                errors.broker = "Please select a Broker";
            }
            if (!values.login) {
                errors.login = "Please enter a Account ID for your Broker trading account!";
            }
            if (/\D/.test(values.login)) {
                errors.login = "Please enter numbers only Account ID for your Broker trading account!";
            }
            if (!newBot.isDownload) {
                if (!values.server) {
                    errors.server = "Please select a Server";
                }
                if (!values.password) {
                    errors.password = "Please enter a Password";
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            dispatch(onNewBot({ ...newBot, ...values }, "broker", false, props.router.navigate));
        }
    });

    const onBack = () => {
        dispatch(onNewBot({ ...validation.values }, "broker", true, props.router.navigate));
    }

    const [passwordShow, setPasswordShow] = useState(false);

    useEffect(() => {
        dispatch(listBrokers());
        dispatch(listBrokerServers());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="New Robot" items={[DASHBOARD_ITEM, {title: "My Robots", url: "/bots"}]} />
                    <Row className="justify-content-center">
                        <Col md="11" xl="8">
                            <div className="step-bar">
                                <div className="step complete">
                                    <button className="number">1</button>
                                    <p>Choose a robot</p>
                                </div>
                                <div className="bar-complete"></div>
                                <div className="step active">
                                    <button className="number">2</button>
                                    <p>Select broker</p>
                                </div>
                                <div className="bar-complete"></div>
                                <div className="step">
                                    <button className="number">3</button>
                                    <p className="px-3">Finish</p>
                                </div>
                            </div>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                                <Card className="px-lg-5">
                                    <CardBody>
                                        <CardTitle>Broker Details</CardTitle>
                                        <div className="card-title-desc">
                                            <ul>
                                                <li><strong>MetaTrader 4</strong> trading account, from your chosen <strong>Broker</strong></li>
                                                <li>Ensure you have registered with one of our affiliate brokers</li>
                                                <li>Please check <Link to="/setup">Setup</Link> for tasks needed to complete before running a robot</li>
                                                <li>A minimum trading balance of <strong>$10,000</strong> is required or <strong>$100</strong> deposit in a <strong>cent trading account</strong></li>
                                                {/* <li>Using one of our affiliate brokers is required.</li> */}
                                            </ul>
                                        </div>
                                        <Row className="mb-3">
                                            <Label htmlFor="form-row-broker-Input" className="col-md-4 col-form-label">Broker</Label>
                                            <div className="col-md-8">
                                                <Select className="select2-selection"
                                                    id="form-row-broker-Input"
                                                    name="broker"
                                                    onChange={option => {
                                                        if (!newBot.isDownload) {
                                                            setServers(brokerServers.filter(b => b.brokerId == option.value));
                                                            serverInputRef.current.clearValue();
                                                        }
                                                        let event = {target: {name: 'broker', value: option}};
                                                        validation.handleChange(event);
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.broker || ""}
                                                    isLoading={brokerLoading}
                                                    isSearchable={true}
                                                    options={brokers?.map(b => ({label: b.name, value: b.id}))}
                                                />
                                            </div>
                                        </Row>
                                        { !newBot.isDownload && <Row className="mb-3">
                                            <Label htmlFor="form-row-server-Input" className="col-md-4 col-form-label">Server</Label>
                                            <div className="col-md-8">
                                                <Select className="select2-selection"
                                                    id="form-row-server-Input"
                                                    name="server"
                                                    ref={serverInputRef}
                                                    // value={selectedServer}
                                                    onChange={option => {
                                                        let event = {target: {name: 'server', value: option}};
                                                        validation.handleChange(event);
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.server || ""}
                                                    isLoading={brokerLoading}
                                                    isSearchable={true}
                                                    options={servers.map(s => ({ label: s.name, value: s.server }))}
                                                />
                                                {(validation.touched.server && validation.errors.server)
                                                    && <FormFeedback type="invalid">{validation.errors.server}</FormFeedback>}
                                            </div>
                                        </Row> }
                                        <Row className="mb-3">
                                            <Label htmlFor="form-row-login-Input" className="col-md-4 col-form-label">Account ID #</Label>
                                            <div className="col-md-8">
                                                <Input
                                                    name="login"
                                                    type="text"
                                                    className="form-control"
                                                    id="form-row-login-Input"
                                                    placeholder="Enter MetaTrader 4 account ID number, example: 12345678"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.login || ""}
                                                    invalid={validation.touched.login && validation.errors.login ? true : false}
                                                />
                                                {(validation.touched.login && validation.errors.login)
                                                    && <FormFeedback type="invalid">{validation.errors.login}</FormFeedback>}
                                            </div>
                                        </Row>
                                        { !newBot.isDownload && <Row className="mb-3">
                                            <Label htmlFor="form-row-password-Input" className="col-md-4 col-form-label">Password</Label>
                                            <div className="col-md-8">
                                                <div className="input-group auth-pass-inputgroup">
                                                    <Input
                                                        name="password"
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control"
                                                        id="form-row-password-Input"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={validation.touched.password && validation.errors.password ? true : false}
                                                    />
                                                    <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                                        <i className="mdi mdi-eye-outline"></i>
                                                    </button>
                                                    {(validation.touched.password && validation.errors.password)
                                                        && <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>} 
                                                </div>
                                            </div>
                                        </Row> }
                                        <hr/>
                                        <Row className="mb-3">
                                            <Label htmlFor="formrow-botdesc-Input" className="col-md-4 col-form-label">Description (optional)</Label>
                                            <div className="col-md-8">
                                                <Input
                                                    name="description"
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-botdesc-Input"
                                                    placeholder="Enter your comment / description (optional)"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.description || ""}
                                                />
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <div className="nav-buttons">
                                    <Button color="primary" outline onClick={onBack}>Back</Button>
                                    <Button color="primary" disabled={creating} type="submit">
                                        Done
                                        {creating && <Spinner isButton={true}/>}
                                    </Button>
                                </div>
                            </Form>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

NewBotBrokerPage.propTypes = {
    brokerServers: PropTypes.array,
    onListBrokers: PropTypes.func,
};

export default withRouter(NewBotBrokerPage);