import { isNotNull } from "helpers/util";
import { FormattedNumber } from 'react-intl'
import PlaceHolder from "./PlaceHolder";

const PrctFormat = ({value, isNull = undefined, noTrailingZeros = false, isProfitLoss = false, isLoading = false}) => {

  const isPositive = value >= 0;
  
  if (isLoading) {
    return <PlaceHolder loadingMessage=""/>
  }
  
  if ((isNull != undefined && isNull()) || !isNotNull(value) ) {
    return <div className="px-2">-</div>
  }

  const nf = <FormattedNumber value={value} style="percent" trailingZeroDisplay={noTrailingZeros ? "stripIfInteger" : "auto"}/>;

  if (!isProfitLoss) {
    return nf;
  }
  return (
    <span className="text-nowrap">
      {nf}
      {isPositive 
      ? <i className="mdi mdi-arrow-up text-success"/>
      : <i className="mdi mdi-arrow-down text-danger"/>}
    </span>
  );
}

export default PrctFormat;